import React, { useState, useEffect, Fragment } from "react";
import { AiOutlineProduct } from "react-icons/ai";
import { FiBox } from "react-icons/fi";
import { MdOutlineCategory } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { Grid, TextField } from "@mui/material";
import { TbLogout2 } from "react-icons/tb";
import logo from "../../../Assets/images/logo.png";
import logo01 from "../../../Assets/images/logo-1.png";
import logo02 from "../../../Assets/images/logo-2.png";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import whatsAppIcon from "../../../Assets/images/whatsapp-icon.png";
import mailIcon from "../../../Assets/images/mail-icon.png";
import telephoneIcon from "../../../Assets/images/telephone-icon.png";
import telegramIcon from "../../../Assets/images/telegram-icon.png";
import AddProductForm from "../../Components/AddProductForm/AddProductForm";
import ProductCard from "../../Components/ProductCard/ProductCard";
import CategoryImageCard from "../../Components/CateogryImageCard/CateogryImageCard";
import UserCard from "../../Components/UserCard/UserCard";
import OrderDetails from "../../Components/OrderDetails/OrderDetails";
import { useMediaQuery } from "react-responsive";
import { set } from "react-hook-form";

//   Sort functionality
const sortOptions = [
  { name: "All", href: "#", current: true },
  { name: "sherwani", href: "#", current: false },
  { name: "indowestern", href: "#", current: false },
  { name: "jodhpuri", href: "#", current: false },
  { name: "koti-kurta", href: "#", current: false },
  { name: "tuxedo", href: "#", current: false },
  { name: "suit", href: "#", current: false },
  { name: "kurta", href: "#", current: false },
  { name: "formals", href: "#", current: false },
];

const AdminDashboard = () => {
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    if (!token) {
      window.location.href = "/admin/login";
    }
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  const [currentSort, setCurrentSort] = useState(sortOptions[0]);
  const [filteredProducts, setFilteredProducts] = useState(allProducts);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [addproductPopup, setAddProductPopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  

  //   Tab change functionality for sidebar
  const [activeTab, setActiveTab] = useState("manageProducts");
  const handleTabClick = (tab) => {
    setActiveTab(tab);

    if (tab === "manageOrders") {
      fetchOrderDetails();
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //-------------------------------- Manage Product functionality------------------------------
  //-------------------------------- Fetch All Products--------------------------------------
  const fetchAllProducts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/product/all-products`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }

      const data = await response.json();
      if (data.success) {
        setAllProducts(data.data);
        setFilteredProducts(data.data); // Initialize filteredProducts with all products
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const sortProducts = (sortType) => {
    let sortedProducts = [];

    // Check if sortType is valid and a string
    if (typeof sortType === "string" && sortType.toLowerCase() === "all") {
      sortedProducts = allProducts; // Show all products
    } else {
      sortedProducts = allProducts.filter(
        (product) =>
          product.category &&
          product.category.toLowerCase() === sortType.toLowerCase()
      );
    }

    setFilteredProducts(sortedProducts);
  };

  const handleOptionClick = (option) => {
    setCurrentSort(option.name);
    sortProducts(option.name);

    const updatedOptions = sortOptions.map((opt) =>
      opt.name === option.name
        ? { ...opt, current: true }
        : { ...opt, current: false }
    );
    // Use updatedOptions directly for better state management
    sortOptions.splice(0, sortOptions.length, ...updatedOptions);
  };

  const refreshProducts = async () => {
    // Ensure fetching completes before sorting
    await fetchAllProducts();
    // sortProducts(currentSort);
  };

  // ----------------------Delete Product Functionality-------------------------

  const handleProductDelete = async (product_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/product/delete-product/${product_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        alert("Failed to delete product. Product is in orders of user.");
      }

      const data = await response.json();
      if (data.success) {
        // Refresh the product list after deletion
        refreshProducts();
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // ----------------------Add Product Popup Functionality-------------------------
  const handleAddProduct = () => {
    setIsEditMode(false);
    setProductData(null);
    setIsFormVisible(true);
  };

  const handleFromClose = () => {
    setIsFormVisible(false);
  };

  // ----------------------Edit Product Functionality-------------------------
  const handleProductEdit = (product_id) => {
    const product = allProducts.find(
      (product) => product.product_id === product_id
    );
    setProductData(product);
    setIsEditMode(true);
    setIsFormVisible(true);
  };

  //----------------------------Users with SearchBar Functionality-------------------------
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/user/user-details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        if (data.success) {
          setUsers(data.users);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    getUserDetails();
  }, [searchTerm]);

  const filteredUsers = users.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // --------------------Category Images---------------------

  // ----------------------To Fetch Category Images----------------------------
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategoryImages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/product/category-images`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
          setCategories(data.data);
        } else {
          console.error("Failed to fetch category images");
        }
      } catch (error) {
        console.error("Error fetching category images:", error);
      }
    };

    fetchCategoryImages();
  }, []);

  // ----------------------To Delete Category----------------------------
  const handleDeleteCategory = async (category_id) => {
    alert("Are you sure you want to delete this category?");
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/product/delete-category/${category_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete category");
      }

      const data = await response.json();
      if (data.success) {
        setCategories(
          categories.filter((category) => category.category_id !== category_id)
        );
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
      finally {
        setLoading(false); // Ensure loading is set to false
      }
  };


  // -------------------------To Add Category Images----------------------------
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    if (imageFile) {
      formData.append("category_image", imageFile);
      formData.append("category_name", e.target.category_name.value);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/product/add-category`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add category");
      }

      const data = await response.json();

      if (data.success) {
        setCategories([...categories, data.data]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };


  


  // ----------------------Admin Logout Functionality-------------------------

  const hanldeAdminLogout = () => {
    localStorage.removeItem("adminToken");
    window.location.href = "/admin/login";
  };

  // ----------------------FETCH ALL ORDERS FUNCTIONALITY-------------------------
  const [allOrders, setallOrders] = useState([]);

  const fetchOrderDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/admin/order/all-orders`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        alert("Failed to fetch orders. Please try again later.");
      }

      const data = await response.json();

      if (data.success) {
        setallOrders(data.orders);
      } else {
        alert("Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });
  const iconSize = isSmallScreen ? 22 : 30;

  return (
    <div>
      <>
        {/* Navbar */}
        <div className="flex items-center justify-between px-2 py-4 sm:px-12 bg-[#501D01]">
          <div className="flex items-center justify-center ml-2 sm:ml-0">
            <img
              src={logo01}
              className=" w-[7rem] h-[6rem] hidden lg:block"
              alt="logo"
            />
            <img src={logo02} className="lg:h-[2rem] h-[2.5rem] " alt="logo" />
          </div>
          {/* Logout */}
          <div
            className="flex items-center sm:gap-6 cursor-pointer hover:text-[#EDB92B] "
            onClick={hanldeAdminLogout}
          >
            <TbLogout2 size={30} color="white"/>
            <p className="hidden sm:block text-[20px]">Logout</p>
          </div>
        </div>

        {/* Admin Dashboard Heading */}
        <h1 className="text-center text-[#A5320C] text-[24px] font-bold my-5 hidden lg:block">
          Admin Dashboard
        </h1>

        <div className="lg:flex items-start justify-start w-full grid-cols-1 p-[2rem] lg:grid-cols-2 border">
          {loading && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
              <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
            </div>
          )}
          <div className="lg:flex lg:w-[30%] w-full lg:gap-10">
            {/* Sidebar */}
            <div className="grid grid-cols-2 gap-2 lg:gap-4 lg:block">
              {/* Manage Products */}
              <div
                onClick={() => handleTabClick("manageProducts")}
                className={`lg:border-none border lg:p-0 sm:px-5 px-1 py-2 border-[#E85826] flex items-center lg:gap-6 sm:gap-2 gap-0 mb-[2rem] cursor-pointer ${
                  activeTab === "accountDetails" ? " text-[#EDB92B]" : ""
                } hover:text-[#EDB92B]`}
              >
                <AiOutlineProduct size={iconSize} />
                <p className="text-[20px] hidden lg:block">Manage Products</p>
                <p className="sm:text-[20px] text-[16px] block lg:hidden">
                  Products
                </p>
              </div>

              {/* Manage Orders */}
              <div
                onClick={() => handleTabClick("manageOrders")}
                className={`lg:border-none border lg:p-0 sm:px-5 px-1 py-2 border-[#E85826] flex items-center lg:gap-6 sm:gap-2 gap-0 mb-[2rem] cursor-pointer ${
                  activeTab === "myOrders" ? "text-[#EDB92B]" : ""
                } hover:text-[#EDB92B]`}
              >
                <FiBox size={iconSize} />
                <p className="text-[20px] hidden lg:block">Manage Orders</p>
                <p className="sm:text-[20px] text-[16px] block lg:hidden">
                  Orders
                </p>
              </div>

              {/* Manage Category */}
              <div
                onClick={() => handleTabClick("manageCategory")}
                className={`lg:border-none border lg:p-0 sm:px-5 px-1 py-2 border-[#E85826] flex items-center lg:gap-6 sm:gap-2 gap-0 mb-[2rem] cursor-pointer ${
                  activeTab === "addresses" ? "text-[#EDB92B]" : ""
                } hover:text-[#EDB92B]`}
              >
                <MdOutlineCategory size={iconSize} />
                <p className="text-[20px] hidden lg:block">Manage Category</p>
                <p className="sm:text-[20px] text-[16px] block lg:hidden">
                  Category
                </p>
              </div>

              {/* Users */}
              <div
                onClick={() => handleTabClick("users")}
                className={`lg:border-none border lg:p-0 sm:px-5 px-1 py-2 border-[#E85826] flex items-center lg:gap-6 sm:gap-2 gap-0 mb-[2rem] cursor-pointer ${
                  activeTab === "helpCenter" ? "text-[#EDB92B]" : ""
                } hover:text-[#EDB92B]`}
              >
                <FiUsers size={iconSize} />
                <p className="text-[20px] hidden lg:block">Users</p>
                <p className="sm:text-[20px] text-[16px] block lg:hidden">
                  Users
                </p>
              </div>
            </div>

            <div className="hidden lg:block border-[1px] border-gray-300 opacity-40 shadow-lg h-100"></div>
          </div>

          {/* Sidebar's content  */}
          {/* // Manage Products */}
          {activeTab === "manageProducts" && (
            <div className="lg:w-[80%] w-full">
              <div className="flex items-center justify-between w-full text-[#A5320C]">
                <Menu as="div" className="relative inline-block text-left">
                  <div className="bg-[#FFF7E7] w-[8rem] h-[2rem] border border-gray-400 rounded-sm text-center ">
                    <Menu.Button className="inline-flex justify-end font-semibold text-[20px] text-gray-600 group hover:text-gray-700 gap-3">
                      Sort
                      <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 text-gray-600 ml-1px w-[2rem] group-hover:text-gray-700"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 w-40 mt-2 origin-top-right bg-[#F9F3E6] rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                href={option.href}
                                onClick={() => handleOptionClick(option)}
                                className={classNames(
                                  option.current
                                    ? "font-semibold text-gray-900"
                                    : "text-gray-500 font-semibold",
                                  active ? "bg-[#ede4d1]" : "",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={handleAddProduct}
                >
                  <MdOutlineLibraryAdd size={22} />
                  <h1 className="text-[16px] font-semibold ">Add product</h1>
                </div>
              </div>

              <div>
                {filteredProducts.length > 0 ? (
                  filteredProducts
                    .slice()
                    .sort(
                      (a, b) =>
                        new Date(b.date_of_adding_product) -
                        new Date(a.date_of_adding_product)
                    )
                    .map((product) => (
                      <ProductCard
                        key={product.product_id}
                        product={product}
                        handleProductDelete={handleProductDelete}
                        handleProductEdit={handleProductEdit}
                      />
                    ))
                ) : (
                  <h1 className="text-[20px] font-semibold text-[#A5320C] text-center mt-10">
                    No products found
                  </h1>
                )}
              </div>
            </div>
          )}

          {/* Manage Orders */}
          {activeTab === "manageOrders" && (
            <div className="w-full">
              <h1 className="text-[20px] font-semibold mb-5">All Orders</h1>

              {allOrders.length > 0 ? (
                allOrders
                  .sort((a, b) => b.order_id - a.order_id)
                  .map((allOrder) => (
                    <OrderDetails allOrder={allOrder} key={allOrder.order_id} />
                  ))
              ) : (
                <h1 className="text-[20px] font-semibold text-[#A5320C] text-center mt-10">
                  No orders found
                </h1>
              )}
            </div>
          )}
          {/* manage Category */}
          {activeTab === "manageCategory" && (
            <div className="lg:w-[80%] w-full lg:mt-0 mt-10">
              <h1 className="text-[18px]  font-semibold mb-10">Add Category</h1>
              <form onSubmit={handleAddCategory} encType="multipart/form-data">
                <div className="grid grid-cols-1 gap-[32px] lg:grid-cols-2">
                  <div>
                    <label className="block font-medium">Category Name</label>
                    <input
                      type="text"
                      id="category_name"
                      name="category_name"
                      required
                      className="w-full p-2 mt-1 border border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 font-medium">
                      Upload Category Image{" "}
                      <span className="text-red-500 opacity-70">
                        (Size: 3MB max, Resolution: 405x480)
                      </span>
                    </label>
                    <input
                      type="file"
                      id="category_image"
                      name="category_image"
                      required
                      accept="image/*"
                      className="w-full"
                      onChange={handleImageUpload}
                    />
                    {imagePreview && (
                      <div className="mt-4">
                        <img
                          src={imagePreview}
                          alt="Category Preview"
                          className="object-cover w-32 h-32"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-10">
                  <button
                    type="submit"
                    className="w-full py-2 font-semibold text-[18px] text-white bg-[#E85826] hover:bg-[#A5320C]"
                  >
                    Add Category
                  </button>
                </div>
              </form>

              <div>
                <h1 className="text-[18px]  font-semibold mt-10 mb-10">
                  Present Categories
                </h1>

                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  {categories.map((category) => (
                    <CategoryImageCard
                      key={category.category_id}
                      handleDeleteCategory={handleDeleteCategory}
                      category={category}
                      setLoading={setLoading}
                      setCategories={setCategories}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Manage Users */}
          {activeTab === "users" && (
            <div className="lg:w-[80%] w-full lg:mt-0 mt-10">
              <h1 className="text-[20px] font-semibold mb-10">All Users</h1>

              {/* Search bar */}
              <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 mb-10 bg-transparent border rounded-sm shadow-md focus:outline-none"
              />

              <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {filteredUsers.length > 0 ? (
                  filteredUsers.map((user) => (
                    <UserCard key={user.id} user={user} />
                  ))
                ) : (
                  <p>No users found.</p>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Add Product Popup */}
        {/* Add Product Popup */}
        {isFormVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999] w-full">
            <div className="relative lg:p-[3rem] p-5 bg-[#FEFAF2] shadow-lg lg:w-[90%] lg:h-[98%] w-[95%] h-[98%] overflow-y-auto">
              <AddProductForm
                handleFromClose={handleFromClose}
                isEditMode={isEditMode}
                productData={productData}
                refreshProducts={refreshProducts}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default AdminDashboard;
