import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const CateogryImageCard = ({
  category,
  handleDeleteCategory,
  setCategories,
  setLoading,
}) => {

  const token = localStorage.getItem("adminToken");
  const [editCategory, setEditCategory] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleEditCategory = async (category_id, e) => {
    setEditCategory(false);
    setLoading(true);

    const formData = new FormData();
    if (imageFile) {
      formData.append("category_image", imageFile);
    }
    formData.append("category_name", e.target.category_name.value);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/product/update-category/${category_id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update category");
      }

      const data = await response.json();

      if (data.success) {
        // Update the categories state with the updated category
        alert("Category updated successfully");
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.category_id === category_id ? data.data : category
          )
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false); 
      
    }
  };

  return (
    <>
      <div className="bg-[#FEFAF2] shadow-lg rounded-md relative">
        <img
          className="w-full"
          src={category.category_imageurl}
          alt="category-representation"
        />
        <div className="flex items-center justify-between w-full px-2">
          <FaRegEdit
            size={22}
            className="cursor-pointer text-[#E85826]"
            onClick={() => setEditCategory(true)}
          />

          <p className="text-center text-[20px] font-semibold my-2 text-[#E85826] flex-grow">
            {category.category_name.toUpperCase()}
          </p>
          <MdDelete
            size={22}
            className="cursor-pointer text-[#E85826]"
            onClick={() => handleDeleteCategory(category.category_id)}
          />
        </div>
      </div>

      {editCategory && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999] w-full">
          <div className="relative lg:p-[3rem] p-5 bg-[#FEFAF2] shadow-lg overflow-y-auto">
            <div className="flex items-center justify-between">
              <h1 className="text-[18px]  font-semibold mb-10">
                Update Category
              </h1>
              <IoMdClose
                size={22}
                className="cursor-pointer"
                onClick={() => setEditCategory(false)}
              />
            </div>

            <form onSubmit={(e) => { e.preventDefault(); handleEditCategory(category.category_id, e); }} encType="multipart/form-data">

              <div className="grid grid-cols-1 gap-[32px] lg:grid-cols-2">
                <div>
                  <label className="block font-medium">Category Name</label>
                  <input
                    type="text"
                    id="category_name"
                    name="category_name"
                    required
                    className="w-full p-2 mt-1 border border-gray-300 rounded"
                  />
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    Upload Category Image{" "}
                    <span className="text-red-500 opacity-70">
                      (Size: 3MB max, Resolution: 405x480)
                    </span>
                  </label>
                  <input
                    type="file"
                    id="category_image"
                    name="category_image"
                    required
                    accept="image/*"
                    className="w-full"
                    onChange={handleImageUpload}
                  />
                  {imagePreview && (
                    <div className="mt-4">
                      <img
                        src={imagePreview}
                        alt="Category Preview"
                        className="object-cover w-32 h-32"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-10">
                <button
                  type="submit"
                  className="w-full py-2 font-semibold text-[18px] text-white bg-[#E85826] hover:bg-[#A5320C]"
                >
                  Update Category
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CateogryImageCard;
