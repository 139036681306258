import Carousel01 from "../../Assets/images/carousel01.png";
import Carousel02 from "../../Assets/images/carousel02.png";
import Carousel03 from "../../Assets/images/carousel03.png";
import Carousel04 from "../../Assets/images/carousel04.png";
import Carousel05 from "../../Assets/images/carousel05.png";
import Carousel06 from "../../Assets/images/carousel06.png";
import Carousel07 from "../../Assets/images/carousel07.png";

export const MainCarouselData = [

    {
        image: Carousel07,
    },
    {
        image: Carousel03,
    },
    {
        image: Carousel04,
    },
    {
        image: Carousel05,
    },
    {
        image: Carousel06,
    },
    {
        image: Carousel02,
    },
];
